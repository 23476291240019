import axios from 'axios'
import { BACKEND } from '@src/BACKEND'
import { notifySuccessProgress } from '@src/components/Toast/successToast'
import { getToken } from '@utils'
import FetchRefreshToken from '@refreshToken'

export const FETCH_ENVIO_TRANSFERENCIA_REQUEST = 'FETCH_ENVIO_TRANSFERENCIA_REQUEST'
export const FETCH_ENVIO_TRANSFERENCIA_SUCCESS = 'FETCH_ENVIO_TRANSFERENCIA_SUCCESS'
export const FETCH_ENVIO_TRANSFERENCIA_FAILURE = 'FETCH_ENVIO_TRANSFERENCIA_FAILURE'

export const fetchEnvioTransferenciaRequest = () => {
    return {
        type: FETCH_ENVIO_TRANSFERENCIA_REQUEST
    }
}

export const fetchEnvioTransferenciaSuccess = (prod) => {
    return {
        type: FETCH_ENVIO_TRANSFERENCIA_SUCCESS,
        payload: prod
    }
}

export const fetchEnvioTransferenciaFailure = (error) => {
    return {
        type: FETCH_ENVIO_TRANSFERENCIA_FAILURE,
        payload: error
    }
}

const FetchEnvioTransferencia = (idAdmin, idEmpresa) => {
    return (dispatch) => {
        dispatch(fetchEnvioTransferenciaRequest())

        return axios({
            method: 'PUT',
            headers: { Authorization: `Bearer ${getToken()}` },
            url: `${BACKEND}admin/${idAdmin}/${idEmpresa}/modificarEstadoTransferencia`
        })
        .then(res => {
            dispatch(fetchEnvioTransferenciaSuccess(res.data))
            notifySuccessProgress(
                "Cambio Estado",
                `El cambio de estado para transferencia se realizo correctamente.`
            )
        })
        .catch(error => {
            dispatch(fetchEnvioTransferenciaFailure(error.response))

            dispatch(FetchRefreshToken(
                {
                    status: error.response?.status,
                    fnc: () => FetchEnvioTransferencia(idAdmin, idEmpresa)
                }
            ))
        })
    }
}


export default FetchEnvioTransferencia
